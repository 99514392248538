<template>
  <div class="h-full">
    <Scrollbar>
      <Table :options="handleOptions" :loadData="handleLoadData" title="幸福卡发放记录" ref="table">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="80px">
            <SemesterClassSelect :refreshBtn="false" v-model="formSearch" ></SemesterClassSelect>
            <el-form-item label="学号">
              <el-input v-model="formSearch.number" placeholder="学号"></el-input>
            </el-form-item>
            <el-form-item label="学生">
              <el-input v-model="formSearch.realName" placeholder="学生姓名"></el-input>
            </el-form-item>
<!--            <el-form-item label="发放人">-->
<!--              <el-input v-model="formSearch.teacherName" placeholder="发放人"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
              <el-button @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="handleCreate">发卡</el-button>
            <!--            <el-button type="primary" icon="el-icon-plus" @click="handleExport">导出</el-button>-->
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" @click="handleDetail(row)">详情</el-button>
          <el-button type="text" @click="handleRecording(row)">记录</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from '@/pages/web/components/grantEdit.vue'
import detail from '@/pages/web/components/grantDetail.vue'
import recording from '@/pages/web/components/grantRecording.vue'
export default {
  name: "medal",
  data() {
    return {
      medalList: [],
      semesterOptions:[],
      grade:[],
      classesTree:[],
      cardOptions:[],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        semesterId: '',
        classId:'',
        number: '',
        realName: ''
      }
    };
  },
  async created() {
    await this.handleLoadMedal()
  },
  async mounted() {
    await this.handleLoadSemester()
    await this.getClassesTree();  // 获取所有班级
    await this.handleLoadCard();  // 获取所有奖章
  },
  methods: {
    async handleLoadSemester(){
      await this.$cloud.get('semester/list',{
        page:1,
        size:99999
      }).then(res=>{
        this.semesterOptions = res.children || []
      })
    },
    // 获取卡
    async handleLoadMedal() {
      await this.$cloud.get('card/list', {
        page: 1,
        size: 99999,
      }).then(res => {
        this.medalList = []
        if (res?.children?.length) {
          this.medalList = res.children
        }
      })
    },
    // 获取班级待选树状数据
    async getClassesTree(){
      await this.$cloud.post('/classes/list/tree').then(res=>{
        this.classesTree = res
      })
    },
    // 获取所有奖章
    async handleLoadCard(){
      await this.$cloud.get('card/list',{
        page:1,
        size:99999
      }).then(res=>{
        this.cardOptions = res.children || []
      })
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/award/record").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("card/student/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },

    //重置
    handleReset() {
      this.formSearch = {
        semesterId: '',
        startDate: '',
        endDate: '',
        cardId:'',
        classId:'',
        studentId:'',
        teacherId:'',
      }
    },
    // 发卡
    handleCreate() {
      this.$cloud.dialog({
        title: '发卡',
        data: {
          semesterId: this.formSearch.semesterId
        },
        component: edit,
        success: '发卡成功!',
        warning: '发卡失败!',
        customClass: 'grant-dialog-class',
        refresh: this.handleRefresh
      })
    },

    // 详情
    handleDetail(row) {
      this.$cloud.dialog({
        title: '发卡详情',
        data: {
          ...row,
          semesterId: this.formSearch.semesterId,
          classId: this.formSearch.classId,
        },
        component: detail,
        success: '发卡详情!',
        warning: '发卡详情!',
      })
    },

    handleRecording(row) {
      this.$cloud.dialog({
        title: '发卡详情',
        data: {
          ...row,
          semesterId: this.formSearch.semesterId,
          classId: this.formSearch.classId,
        },
        component: recording,
        success: '发卡记录!',
        warning: '发卡记录!',
        customClass: 'grant-dialog-class',
      })
    },

    // 导出
    handleExport() {
      this.$confirm('确定要导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '导出成功!'
        })
      })
    },
  },
};
</script>
<style lang="scss">
.grant-dialog-class {
  width: 800px;
}
</style>
