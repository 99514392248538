<!--
 * ======================================
 * 说明： 教师端 - 日常发卡  拥有卡详情
 * 作者： Silence
 * 文件： grantDetail.vue
 * 日期： 2023/8/29 1:12
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form v-loading="loading">
    <el-form-item label="学期:">
        {{ semester.semester_name }}
    </el-form-item>
    <el-form-item label="班级:">
        {{ classes.class_name }}
    </el-form-item>
    <el-form-item label="学生:">
        {{ student.real_name }}
    </el-form-item>
    <el-form-item label="幸福卡:">
        <span v-if="model.length === 0">暂无</span>
        <label class="mr-6" v-for="(item,index) in model" :key="index">{{ item['name'] }} x{{ item['count'] }}</label>
    </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:{
        type: Object
      }
    },
    data() {
      return {
        loading:false,
        semester:{},
        classes:{},
        student:{},
        model:[],
      }
    },
    mounted() {
      if(this.value){
        this.handleLoadDetail();
      }
    },
    methods: {
      // 加载详情
      handleLoadDetail(){
        this.loading=true
        this.$cloud.get('card/student/detail', {
          classId: this.value.classId,
          semesterId: this.value.semesterId,
          studentId: this.value.id
        }).then(res => {
          this.model = res.data || [];
          this.semester = res.semester;
          this.classes = res.class;
          this.student = res.student;
          this.loading = false
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>
