<!--
 * ======================================
 * 说明： 教师端 - 日常发卡  发卡记录
 * 作者： Silence
 * 文件： grantDetail.vue
 * 日期： 2023/8/29 1:12
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <el-form label-width="80px" :inline="true">
      <el-form-item label="幸福卡">
        <el-select class="w-40" v-model="cardId" clearable>
          <el-option v-for="item in medalList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleLoadDetail">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="model" style="width: 100%" height="400">
      <el-table-column prop="semester_semester_name" label="学期"></el-table-column>
      <el-table-column prop="classes_class_name" label="班级"></el-table-column>
      <el-table-column prop="student_real_name" label="学生"></el-table-column>
      <el-table-column prop="card_name" label="名称"></el-table-column>
      <el-table-column prop="count" label="数量"></el-table-column>
      <el-table-column prop="created_time" label="时间" width="160"></el-table-column>
    </el-table>
    
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:{
        type: Object
      }
    },
    data() {
      return {
        model:[],
        cardId:'',
        medalList:[],
      }
    },
    computed: {},
    created() {},
    async mounted() {
      await this.handleLoadMedal();
      if(this.value){
        this.handleLoadDetail();
      }
    },
    methods: {
      // 加载详情
      handleLoadDetail(){
        let params = {
          classId: this.value.classId,
          semesterId: this.value.semesterId,
          studentId: this.value.id,
          cardId: this.cardId
        }
        this.$cloud.get('card/student/history', params).then(res => {
          this.model = res || [];
        })
      },
  
      // 获取卡
      async handleLoadMedal(){
        await this.$cloud.get('card/list', {
          page:1,
          size:99999,
        }).then(res=>{
          this.medalList = []
          if(res?.children?.length){
            this.medalList = res.children
            this.cardId = res.children[0].id
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>