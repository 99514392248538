<!--
 * ======================================
 * 说明： 日常发卡  添加
 * 作者： Silence
 * 文件： grantEdit.vue
 * 日期： 2023/8/5 0:38
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
    <el-form class="grant-form-height" v-loading="loading" :disabled="disabled" ref="editForm" :model="editForm" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-table border height="300" :data="studentData" @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="40">
            </el-table-column>
            <el-table-column label="学号" prop="number"></el-table-column>
            <el-table-column label="姓名" prop="real_name"></el-table-column>
            <el-table-column label="性别" prop="sex"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="grant-form-medal mt-4">
        <Scrollbar>
            <el-row>
                <el-col :span="8" v-for="(item, index) in medalList" :key="index">
                  <el-form-item :prop="String(item.id)">
                    <label slot="label">{{ item.name+'：' }}</label>
                    <el-input-number :min="0" :step="1" v-model="medalForm[item.id]"></el-input-number>
                  </el-form-item>
                </el-col>
            </el-row>
        </Scrollbar>
      </div>
    </el-form>
</template>
<script>
import { grade } from '@/utils/global.js'
  export default {
    name: '',
    components: {  },
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {
        data:0,
        grade,
        visible: false,
        loading: false,
        classOptions:[],
        studentData:[],
        medalList:[],
        semesterName:'',
        gradeName:"",
        className:"",
        editForm: {
          grade:'',
          classId:'',
          semesterId:'',
          studentList:[],
        },
        medalForm:{},
        rules: {
          grade: [
            { required: true, message: '请输入年级', trigger: 'blur' }
          ],
          classId: [
            { required: true, message: '请输入班级', trigger: 'blur' }
          ],
        }
      }
    },
    computed: {
      disabled() {
        return this.type === 'view'
      },
    },
    async mounted() {
      this.classOptions=[]
      this.studentData=[]
      this.medalList=[]
      this.editForm={
        semesterId:this.value.semesterId,
        grade:this.value.grade,
        classId:this.value.classId,
        studentList:[],
      }
      await this.handleLoadMedal()

      this.handleLoadStudent()

      // await this.handleLoadSemester()   // 获取当前学期

      // await this.handleLoadClass('first')  // 获取班级

    },
    methods: {
      // 获取幸福卡种类
      async handleLoadMedal(){
        this.loading = true
        await this.$cloud.get('card/list', {
          page:1,
          size:99999,
        }).then(res=>{
          this.loading = false
          if(res?.children?.length){
            this.medalList = res.children
            this.medalList.map(item=>{
              this.$set(this.medalForm, item.id, this.medalForm[item.id] || 0)
              // this.medalForm[item.id] = this.medalForm[item.id] || 0
            })
          }else{
            this.medalList = []
          }
        }).catch(err=>{
          this.loading = false
          // this.$message.error(err)
        })
      },

      // 加载当前学期
      async handleLoadSemester(){
        await this.$cloud.get('semester/now ').then(res=>{
          this.editForm.semesterId = res.id || ''
          this.semesterName = res.semester_name
        })
      },

      // 通过年级id加载班级
      handleLoadClass(num = null){
        if(num !== 'first'){
          this.editForm.classId='';
        }
        this.studentData=[]
        this.loading = true
        this.$cloud.get('classes/list', {
          semesterId: this.editForm.semesterId,
          grade: this.editForm.grade ,
          page:1,
          size:99999
        }).then(res => {
          this.loading = false
          this.classOptions = res.children
          let cc = res.children.find(v=>{
            return v.id === this.editForm.classId
          })
          this.className = cc.class_name
          this.gradeName = cc.grade
          this.handleLoadStudent()
        }).catch(err=>{
          this.loading = false
        })
      },

      // 通过班级id查询班级下的学生
      handleLoadStudent(){
        this.loading = true
        this.$cloud.get('student/list', {
          classId: this.editForm.classId,
          page:1,
          size:99999,
        }).then(res=>{
          this.studentData = res.children
          this.loading = false
        }).catch(err=>{
          this.loading = false
        })
      },

      // 获取选中的数据
      handleSelectionChange(data){
          if(data && data.length){
              this.editForm.studentList = data.map(item=>item.id)
          }
      },

      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['editForm'].validate((valid)=>{
            if(valid){
              let cardList = [];
              for ( const medalListKey in this.medalList ) {
                let item = this.medalList[medalListKey]
                cardList.push({
                  cardId: item.id,
                  count: this.medalForm[item.id] || 0
                })
              }
              let params = {
                semesterId:this.editForm.semesterId,
                studentList:this.editForm.studentList,
                classId:this.editForm.classId,
                cardList,
              }
              this.$cloud.post('card/send',params).then(()=>{
                resolve(true)
              }).catch((err)=>{
                // this.$message.error(err.response?.data?.msg || '提交失败')
                reject(false)
              })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
.grant-form-height{
  height: 530px;
}
.grant-form-medal{
  height: 180px;
  //background-color: red;
}
</style>
